import { ReactNode } from 'react';

interface ContainerProps {
  children?: ReactNode;
  className?: string;
}

const Container = ({ children, className = '' }: ContainerProps) => {
  return (
    <div className={`w-full flex items-center justify-center ${className}`}>
      { children }
    </div>
  )
}

export default Container;