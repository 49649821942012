import { useEffect, useState, Fragment } from "react";

import { getResumes } from "../../lib/resumes";
import { getResumeById } from "../../lib/storage";

const Editor = () => {
    const [resumeUrl, setResumeUrl] = useState("");
    
    useEffect(() => {
        (async function run() {
          const result = await getResumes();
          // check if the data successfully fetched
          if (result) {
            const { resultResume } = result;
            const resume = getResumeById(resultResume[0].fileId);
            resume && setResumeUrl(resume.href);
          } else {
            console.log("Failed to fetch resume data");
          }  
        })()
      }, [])

    const lineNumbers = () => {
        const lines = [];
        for (let index = 1; index < 10; index++) {
            lines.push(
                <Fragment key={index}>
                    {index}
                    <br/>
                </Fragment>
            )
        }
        return lines;
    }
    
    // console.log(getResumeById("667c2dd2001cd7b2f183"));
    

  return (
    <div id='editor' className="h-full bg-[#2D2D2A] rounded-3xl overflow-hidden">
        <div id='editor-content' className="relative h-full pl-20 leading-loose object-cover">
            <div id='line-numbers' className="absolute top-0 bottom-0 left-0 text-right bg-[#3F5E5A] h-full px-9 overflow-hidden">
                {lineNumbers()}
            </div>
            <div className="h-full overflow-x-auto cursor-text">
                <pre id='before'>
                    <p>  {`<`}<span className="text-[#20FC8F]">Jack_Zhou</span></p>
                    <p>    <span className="text-[#98D4FF]">title</span>={`{`}<span className="text-[#00B295]">"Full Stack Developer"</span>{`}`}</p>
                    <p>    <span className="text-[#98D4FF]">techStack</span>={`{`}<span className="text-[#F2E3BC]">{`[`}</span><span className="text-[#00B295]">"React", "Spring Boot", "MySQL"</span><span className="text-[#F2E3BC]">{`]`}</span>{`}`}</p>
                    <p>    <a href="#showcase"><span className="text-[#98D4FF]">showCase</span>={`{`}<span className="text-[#20FC8F] hover:underline underline-offset-8">PROJECT_SHOWCASE</span>{`}`}</a></p>
                    <p>    <a href={resumeUrl} target="_blank" rel="noopener noreferrer"><span className="text-[#98D4FF]">resume</span>={`{`}<span className="text-[#20FC8F] hover:underline underline-offset-8">GET_MY_RESUME</span>{`}`}</a></p>
                    <p>    <a href="#"><span className="text-[#98D4FF]">about</span>={`{`}<span className="text-[#20FC8F] hover:underline underline-offset-8">ABOUT_ME</span>{`}`}</a></p>
                    <p>  {`/>`}</p>
                    <p className="fake-input">  </p>
                </pre>
            </div>
            {/* buttons */}
            {/* <pre id='after'></pre> */}
        </div>
    </div>
  )
}

export default Editor