import Container from "../Container";

interface NavItemProps {
    targetId: string
    title: string;
    icon: string
}

const NavItem = ({ targetId, title, icon }: NavItemProps) => {
  return (
    <div className="translate-x-0 md:hover:-translate-x-48 md:duration-300 motion-reduce:transition-none motion-reduce:hover:transform-none">  
        <a href={`#${targetId}`}>
            <div className="md:transition md:duration-300 mt-2 grid grid-cols-6 bg-[#2D2D2A] hover:bg-[#3F5E5A] h-16 md:h-24 w-72 rounded-l-3xl md:drop-shadow-lg">
                <Container className="col-span-2">
                    <img className="w-14" src={icon} alt="an icon for the nav bar item" />
                </Container>
                <Container className="col-span-4 pr-4">
                    <p className="text-center text-[2rem] leading-none">{title}</p>
                </Container>
            </div>
        </a>
    </div>
  )
}

export default NavItem