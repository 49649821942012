import { databases } from "./appwrite";
import { Resume } from "../types/resume";

const databaseID = process.env.REACT_APP_APPWRITE_PORTFOLIO_DATABASE_ID;
const resumeCollectionID = process.env.REACT_APP_APPWRITE_RESUME_COLLECTION_ID;

export async function getResumes() {
    if (databaseID && resumeCollectionID) {
        const { documents } = await databases.listDocuments(databaseID, resumeCollectionID);  
                      
        // return an object that has another object inside which contains an array of resume items
        return {
            resultResume: documents.map(document => {
                // map the data get from appwrite to local resume objects
                const item: Resume = {
                    $id: document.$id,
                    fileId: document.fileId
                }
                return item;
            })
        }
    }
    return undefined;
}