import Container from "../Container"
import showcaseText from "../../assets/project showcase.png"
import showcase from "../../assets/showcase.png"


const ShowcaseTitle = () => {
  return (
    <Container className="gap-8 px-7 flex-col-reverse md:flex-row">
        <img className="" src={showcaseText} alt="a hand drawn text saying project showcase" aria-label="Project Showcase Section Title" />
        <img className="max-w-[50%]" src={showcase} alt="a hand drawn showcase" />
    </Container>
  )
}

export default ShowcaseTitle