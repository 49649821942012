import ShowcaseTitle from "../ShowcaseTitle"
import ProjectCard from "../ProjectCard"
import Container from "../Container"
import { ProjectItem } from "../../types/projectItem"

import { useEffect, useState } from "react"
import { getProjectImageById } from "../../lib/storage"
import { getProjects } from "../../lib/projects"

import nextPageImg from "../../assets/next page.png"
import prevPageImg from "../../assets/prev page.png"

const Showcase = () => {
  const [projects, setProjects] = useState<Array<ProjectItem>>();
  const [currentProject, setCurrentProjects] = useState<Array<ProjectItem>>();
  const [totalPageNum, setTotalPageNum] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // set the number of items per page based on the device screen size
  const [displayNum] = useState(() => {
    if (window.innerWidth > 768 && window.innerWidth < 1536) {
      return 4;
    } else {
      return 3;
  }}); 

  // try to fetch the project data
  useEffect(() => {
    (async function run() {
      const result = await getProjects();
      // check if the data successfully fetched

      if (result) {
        const { resultProjects } = result;
        setProjects(resultProjects.reverse());
        setCurrentProjects(resultProjects.slice(0, displayNum));
        setTotalPageNum(Math.round(resultProjects.length / displayNum));
      } else {
        console.log("Failed to fetch project data");
      }
    })()
  }, [])

  useEffect(() => {
    const startIndex = (pageNum - 1) * displayNum;
    const endIndex = startIndex + displayNum;
    projects && setCurrentProjects(projects.slice(startIndex, endIndex));
  }, [pageNum])
    
  // implements the change page function
  const nextPage = () => {
    projects && setPageNum(prevPageNum => {
      const nextPage = prevPageNum +1;
      if (nextPage * displayNum >= projects.length + displayNum) {
        return prevPageNum;
      } else {
        return nextPage;
      }
    });
  }

  const prevPage = () => {
    projects && setPageNum(prevPageNum => {
      if (prevPageNum > 1) {
        return prevPageNum - 1;
      } else {
        return 1;
      }
    });
  }

  return (
    <section id="showcase" className="mt-24">
      <ShowcaseTitle />
      <Container className="mt-7 px-8 flex-wrap gap-8">
        {Array.isArray(currentProject) && currentProject.length > 0 && currentProject.map((project) => {
          const imgUrl = getProjectImageById(project.imageFileId);
          if (imgUrl) {
            const image = {
              src: imgUrl.href,
              alt: project.altText
            }
            return <ProjectCard key={project.$id} image={image} title={project.title} demoLink={project.demoLink} gitHubLink={project.githubLink} />
          } else {
            console.log(`Fetch ${project.title} image failed on image id ${project.imageFileId}`);
          }
        })}
      </Container>
      <Container className="justify-around mt-7 gap-4 px-4">
        <button onClick={prevPage} className="md:transition md:duration-300 md:hover:-translate-x-4 motion-reduce:transition-none motion-reduce:hover:transform-none"><img src={prevPageImg} alt="an arrow pointing to the lefting with text of previous page" /></button>
        <p className="text-[2rem]">{pageNum} / {totalPageNum}</p>
        <button onClick={nextPage} className="md:transition md:duration-300 md:hover:translate-x-4 motion-reduce:transition-none motion-reduce:hover:transform-none"><img src={nextPageImg} alt="an arrow pointing to the right with text of next page" /></button>
      </Container>
    </section>
  )
}

export default Showcase