import { storage } from "./appwrite";

const techStackBucketId = process.env.REACT_APP_APPWRITE_TECHSTACK_BUCKET_IMAGE_ID;
const projectBucketId = process.env.REACT_APP_APPWRITE_PROJECTS_BUCKET_IMAGE_ID;
const resumeBucketId = process.env.REACT_APP_APPWRITE_RESUME_BUCKET_ID;

export function getTechStackImageById(fileId: string) {
    if (techStackBucketId) {
        return storage.getFilePreview(techStackBucketId, fileId)
    }
    return undefined;
}

export function getProjectImageById(fileId: string) {
    if (projectBucketId) {
        return storage.getFilePreview(projectBucketId, fileId)
    }
    return undefined;
}

export function getResumeById(fileId: string) {
    if (resumeBucketId) {
        return storage.getFileDownload(resumeBucketId, fileId)
    }
    return undefined;
}