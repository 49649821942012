import Container from "../Container"

import gitHubImg from "../../assets/Social Icons - GitHub.png"
import liveDemoImg from "../../assets/Live Demo Icon.png"

interface ProjectCardProps {
    image: {
        src: string;
        alt?: string;
    };
    demoLink?: string;
    gitHubLink?: string;
    title: string;
}

const ProjectCard = ({ image, demoLink, gitHubLink, title}: ProjectCardProps) => {
  return (
    <section className="relative h-[33rem] w-[30rem] rounded-3xl overflow-clip drop-shadow-lg md:hover:drop-shadow-2xl md:hover:-translate-y-2 md:duration-500 card-parent motion-reduce:transition-none motion-reduce:hover:transform-none">
        {/* if the project has live demo link render a tag */}
        {demoLink && demoLink.length > 0 ? (
            <a href={demoLink} target="_blank" rel="noopener noreferrer">
                <img className="absolute top-0 left-0 w-full h-full object-cover" src={image.src} alt={image.alt} />
            </a>
        ) : (
            <img className="absolute top-0 left-0 w-full h-full object-cover" src={image.src} alt={image.alt} />
        )}

        <div className="absolute p-5 flex flex-col justify-between bottom-0 md:translate-y-24 md:duration-300 h-2/5 w-full bg-[#2D2D2A] md:bg-[#2D2D2A] leading-none card-child motion-reduce:transition-none motion-reduce:hover:transform-none">
            <Container className="text-center text-[2rem] h-full max-h-36 overflow-auto md:overflow-hidden md:hover:overflow-auto">
                <article className="h-full">{title}</article>
            </Container>
            <Container className="gap-8">
                {/* if the project has GitHub link and/or live demo link, render it's buttons */}
                {gitHubLink && gitHubLink.length > 0 && (
                    <a href={gitHubLink} target="_blank" rel="noopener noreferrer"><img className="w-12" src={gitHubImg} alt="A button with GitHub icon that can redirect users to Project's GitHub page" /> </a>
                )}
                {demoLink && demoLink.length > 0 && (
                    <a href={demoLink} target="_blank" rel="noopener noreferrer"><img className="w-14" src={liveDemoImg} alt="A button that can redirect users to Project's live demo page" /> </a>
                )}
            </Container>
        </div>
    </section>
  )
}

export default ProjectCard