import { Client, Databases, Storage } from 'appwrite';

export const client = new Client();
export const databases = new Databases(client);
export const storage = new Storage(client);

const endpoint = process.env.REACT_APP_APPWRITE_ENDPOINT;
const projectID = process.env.REACT_APP_APPWRITE_PROJECT_ID;

if (endpoint && projectID) {
    client
        .setEndpoint(endpoint)
        .setProject(projectID);
}

