import Container from "../Container"
import logo from "../../assets/portfolio logo.png"

const Footer = () => {
  return (
    <footer className="mt-24">
        <Container className="flex-col leading-none text-lg text-center md:text-3xl px-6 h-[33rem] bg-[#353831] drop-shadow-xl">
            <img className="max-w-[70%]" src={logo} alt="the logo of jack's portfolio" />
            <p className="mt-10 md:mt-28">© {new Date().getFullYear()} Jack Zhou | All rights reserved</p>
            <p>Developed by Jack Zhou with React, TypeScript, Appwrite</p>
        </Container>
    </footer>
  )
}

export default Footer