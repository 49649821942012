import NavItem from "../NavItem"

import homeIcon from "../../assets/Nav/home icon.png"
import showcaseIcon from "../../assets/Nav/box icon.png"

const Nav = () => {
  return (
    <nav className="fixed -right-48 z-10 top-0 md:top-32">
        <NavItem targetId={""} title={"Back to Top"} icon={homeIcon} />
        <NavItem targetId={"showcase"} title={"Project Showcase"} icon={showcaseIcon} />
    </nav>
  )
}

export default Nav