import Container from "../Container";

interface StackCardProps {
    image: {
        src: string;
        alt?: string;
    };
    link?: string;
}

const StackCard = ({ image, link }: StackCardProps) => {
    return (
        <>
            {image.src && (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <Container className="md:transition md:duration-300 aspect-square bg-[#2D2D2A] hover:bg-[#3F5E5A] drop-shadow-lg rounded-2xl motion-reduce:transition-none motion-reduce:hover:transform-none">
                        <img src={image.src} alt={image.alt} className="w-1/2" />
                    </Container>
                </a>
            )}
        </>
    );
};

export default StackCard;
