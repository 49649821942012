import Container from "../components/Container"

const NotFound = () => {
  return (
    <Container className="h-screen flex-col">
      <h1 className="text-6xl">Page not found (404)</h1>
      <p className="text-center text-3xl leading-none mt-8">Hey you just discovered a hidden page! Well, not yet.<br />
      Making a hidden page is part of my future plan, so for now,<br />
      please go bcak to the <a href="/" className="text-[#20FC8F] underline">home page</a>.</p>
    </Container>
  )
}

export default NotFound