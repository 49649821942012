import { databases } from "./appwrite";
import { TechStackItem } from "../types/techStackItem";

const databaseID = process.env.REACT_APP_APPWRITE_PORTFOLIO_DATABASE_ID;
const techStackCollectionID = process.env.REACT_APP_APPWRITE_TECHSTACK_COLLECTION_ID;

export async function getTechStack() {
    if (databaseID && techStackCollectionID) {
        const { documents } = await databases.listDocuments(databaseID, techStackCollectionID);        
        // return an object that has another object inside which contains an array of tech stack items
        return {
            resultTechStack: documents.map(document => {
                // map the data get from appwrite to local tech stack item objects
                const item: TechStackItem = {
                    $id: document.$id,
                    name: document.name,
                    url: document.url,
                    altText: document.altText,
                    imageFileId: document.imageFileId
                }
                return item;
            })
        }
    }
    return undefined;
}