import { databases } from "./appwrite";
import { ProjectItem } from "../types/projectItem";

const databaseID = process.env.REACT_APP_APPWRITE_PORTFOLIO_DATABASE_ID;
const projectsCollectionID = process.env.REACT_APP_APPWRITE_PROJECTS_COLLECTION_ID;

export async function getProjects() {
    if (databaseID && projectsCollectionID) {
        const { documents } = await databases.listDocuments(databaseID, projectsCollectionID);        
        // return an object that has another object inside which contains an array of tech stack items
        return {
            resultProjects: documents.map(document => {
                // map the data get from appwrite to local tech stack item objects
                const item: ProjectItem = {
                    $id: document.$id,
                    title: document.title,
                    demoLink: document.demoLink,
                    githubLink: document.githubLink,
                    altText: document.altText,
                    imageFileId: document.imageFileId
                }
                return item;
            })
        }
    }
    return undefined;
}