import Container from "../Container"
import SocialMedia from "../SocialMedia"
import howdyImg from "../../assets/howdy.png"

const SelfIntro = () => {
  return (
    <div className="p-7 w-full flex flex-col gap-12">
        <img className="w-[340px]" src={howdyImg} alt="A hand-drawing greeting message, howdy!" />
        <Container className="flex-col gap-7">
            <h1 className="text-center leading-none">I am <br className="md:hidden" /><span className="text-[6rem] text-[#20FC8F] special-font">Jack Zhou</span><br/>
            A Full Stack Software Engineer in Toronto, CA</h1>
            <SocialMedia />
        </Container>
    </div>
  )
}

export default SelfIntro