import Container from "../Container"
import linkedInImg from "../../assets/Social Icons - LinkedIn.png"
import gitHubImg from "../../assets/Social Icons - GitHub.png"
import whatsAppImg from "../../assets/Social Icons - WhatsApp.png"
import emailImg from "../../assets/Social Icons - Email.png"

const SocialMedia = () => {
  return (
    <Container className="gap-7">
        <a href="https://www.linkedin.com/in/haopengzhou/"><img className="w-12 hover:-translate-y-2 duration-500" src={linkedInImg} alt="A button with LinkedIn icon that can redirect users to Jack's LinkedIn site" /> </a>
        <a href="https://github.com/Jackoltrade"><img className="w-12 hover:animate-spin" src={gitHubImg} alt="A button with GitHub icon that can redirect users to Jack's GitHub homepage" /> </a>
        <a href="https://wa.me/16479157599"><img className="w-12 hover:animate-spin" src={whatsAppImg} alt="A button with WhatsApp icon that can redirect users to Jack's WhatsApp information" /> </a>
        <a href="mailto:jackzhou.work@protonmail.com"><img className="w-12 hover:-translate-y-2 duration-500" src={emailImg} alt="A button with an envelop icon that can create a draft email to Jack's email address with user's default eamil app" /> </a>
    </Container>
  )
}

export default SocialMedia