import './styles/global.css'

import { Route, Switch } from 'wouter';

import Index from './pages/Index';
import Login from './pages/login';
import NotFound from './pages/NotFound';

const Router = () => {
    return (
      <>
        <Switch>
            <Route path={"/"} component={Index} />
            <Route path={"/login"} component={Login} />
            {/* Catch-all route for 404 page */}
            <Route component={NotFound} />
        </Switch>
      </>
    )
  }
  

function App() {
    return <Router />
}

export default App;
