import { ReactNode } from 'react'

import Nav from '../Nav';
import Header from '../Header';
import Footer from '../Footer';

interface LayoutProps {
  children?: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="mt-24 cursor-default">
      <Nav />
      <Header />
      <main>{ children }</main>
      <Footer />
    </div>
  )
}

export default Layout;