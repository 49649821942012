import avatar from "../../assets/Avatar/Jack.png"

import Editor from "../Editor"
import Container from "../Container"
import SelfIntro from "../SelfIntro"
import TechStack from "../TechStack"


const Header = () => {
  return (
    <Container>
        <header id="header" className="w-11/12 grid grid-rows-3 grid-cols-1 md:grid-rows-2 md:grid-cols-3 gap-4 md:gap-8 custom-grid-rows">
            <section className="md:col-span-2">
                <SelfIntro />
            </section>
            <section className="relative avatar rounded-3xl overflow-hidden drop-shadow-lg">
              <img className="absolute top-0 left-0 w-full h-full object-cover" src={avatar} alt="Jack Zhou is smiling at the camera" />
            </section>
            <div id="editor-space" className="md:col-span-2 hidden md:block drop-shadow-lg" aria-hidden="true">
              <Editor />
            </div>
            <section>
                <TechStack />
            </section>
        </header>
    </Container>
  )
}

export default Header