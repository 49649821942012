import Container from "../Container"
import StackCard from "../StackCard"
import { TechStackItem } from "../../types/techStackItem"
import { useEffect, useState } from "react"
import { getTechStack } from "../../lib/techstack"
import { getTechStackImageById } from "../../lib/storage"

import myTechStack from "../../assets/my tech stack.png"
import techStackArrow from "../../assets/tech stack arrow.png"



const TechStack = () => {
  const [techStack, setTechStack] = useState<Array<TechStackItem>>();

  // try to fetch the tech stack data
  useEffect(() => {
    (async function run() {
      const result = await getTechStack();
      // check if the data successfully fetched
      if (result) {
        const { resultTechStack } = result;
        setTechStack(resultTechStack);
      } else {
        console.log("Failed to fetch tech stack data");
      }  
    })()
  }, [])

  return (
    <Container className="flex-col h-full mt-2 md:mt-0 justify-between">
      {/* if the data exist and not empty, render the following content */}
      {Array.isArray(techStack) && techStack.length > 0 && (
        <>
          <Container className="xl:p-7">
              <div className="grid gap-2 2xl:gap-4 grid-cols-3 md:grid-cols-4 h-full w-full">
                {techStack.map((stack) => {
                  const imageUrl = getTechStackImageById(stack.imageFileId); 
                  if (imageUrl) {
                    const image = {
                      src: imageUrl.href,
                      alt: stack.altText
                    }
                    return (
                      <StackCard key={stack.$id} image={image} link={stack.url} />
                    )
                  } else {
                    console.log(`Fetch ${stack.name} image failed on image id ${stack.imageFileId}`);
                    return null;
                  }
                })}
              </div>
          </Container>
          <Container className="relative grow overflow-hidden mt-3">
            <img className="object-cover absolute top-0" src={techStackArrow} alt="an arrow pointing to my tech stack icons" aria-hidden="true" />
          </Container>
          <Container>
            <img className="w-64" src={myTechStack} alt="an hand drawn text 'my text stack', I am demonstrating my development stack in this section" />
          </Container>
        </>
      )}
    </Container>
  )
}

export default TechStack